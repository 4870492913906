var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "management row no-gutters" },
    [
      _c("nav", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "main-nav row no-gutters" }, [
          _c(
            "div",
            { staticClass: "col-3" },
            [
              _c("router-link", {
                staticClass: "main-nav__logo main-nav__item block-md--xl-left",
                attrs: { to: {} },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-7 height-xs--inherit" }, [
            _c(
              "ul",
              {
                staticClass:
                  "row no-gutters justify-content-center height-xs--inherit delivery-header-text",
              },
              [_vm._v(" " + _vm._s(this.headerText) + " ")]
            ),
          ]),
          _c("div", { staticClass: "col-2 height-xs--inherit" }, [
            _c(
              "ul",
              {
                staticClass:
                  "row no-gutters justify-content-end height-xs--inherit",
              },
              _vm._l(_vm.menus, function (menu) {
                return _c("nav-menu", {
                  key: Math.random(),
                  staticClass: "col-auto",
                  attrs: { menu: menu },
                })
              }),
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mt-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", "offset-sm": "3" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-text", { staticClass: "info-text" }, [
                        _vm._v(
                          "To turn delivery on or off for an airport, please use the toggle below.  The site will automatically refresh with the closed status."
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", "offset-sm": "3" } },
                [
                  _c("AirportDeliveryManagement", {
                    attrs: { items: _vm.items },
                    on: { change: _vm.onItemClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }