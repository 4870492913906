<template>
  <div class="management row no-gutters">
    <nav class="col-md-12">
      <div class="main-nav row no-gutters">
        <div class="col-3">
          <router-link
            :to="{}"
            class="main-nav__logo main-nav__item block-md--xl-left"
          />
        </div>
        <div class="col-7 height-xs--inherit">
          <ul
            class="row no-gutters justify-content-center height-xs--inherit delivery-header-text"
          >
            {{
              this.headerText
            }}
          </ul>
        </div>
        <div class="col-2 height-xs--inherit">
          <ul class="row no-gutters justify-content-end height-xs--inherit">
            <nav-menu
              v-for="menu in menus"
              :key="Math.random()"
              :menu="menu"
              class="col-auto"
            />
          </ul>
        </div>
      </div>
    </nav>
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" sm="6" offset-sm="3">
          <v-card>
            <v-card-text class="info-text"
              >To turn delivery on or off for an airport, please use the toggle
              below.  The site will automatically refresh with the closed
              status.</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="6" offset-sm="3">
          <AirportDeliveryManagement :items="items" @change="onItemClick" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import navMenu from "components/nav_menu.vue";
import NavBar from "components/nav_bar";
import clone from "clone";
import AirportDeliveryManagement from "components/airport_delivery_management";

export default {
    name: "DeliveryPartnerAirportManagement",
    components: {
        NavBar,
        navMenu,
        AirportDeliveryManagement,
    },
    data() {
        return {
            shortCodeMap: {
                "@YG": "At Your Gate",
            },
            menus: [
                {
                    header: () => this.userHeader,
                    items: [
                        {
                            display: "Logout",
                            run: () => {
                                this.logout();

                                this.$router.replace({ name: "login" });
                            },
                        },
                    ],
                },
            ],
            links: [],
        };
    },
    computed: {
        userHeader() {
            if (!this.user) {
                return "";
            }
            if (this.user.firstName != undefined)
                return "Hi " + this.user.firstName + "!";
            return "Hi " + this.user.name + "!";
        },
        items() {
            return clone(this.deliveryAirports);
        },
        headerText() {
            if (this.shortCodeMap[this.user.partnerShortCode] == undefined)
                return "Delivery Management";
            return `${
                this.shortCodeMap[this.user.partnerShortCode]
            } Delivery Management`;
        },
        ...mapGetters(["user"]),
        ...mapGetters("DeliveryStore", ["deliveryAirports"]),
    },
    methods: {
        onItemClick(item) {
            let payload = clone(item);
            this.$nextTick(() => {
                this.setDeliveryEnabled({
                    ...payload,
                    env: "prod",
                });
            });
        },
        ...mapActions("DeliveryStore", [
            "getDeliveryAirportsForDeliveryPartner",
            "setDeliveryEnabled",
        ]),
        ...mapActions(["logout"]),
    },
    created() {
        this.getDeliveryAirportsForDeliveryPartner({
            deliveryPartnerShortCode: this.user.partnerShortCode,
            env: "prod",
        });
    },
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.info-text {
  font-weight: 600 !important;
}
.desktop-nav {
  @include below(sm) {
    display: none;
  }
}

.delivery-header-text {
  align-content: center;
  font-size: 26px;
  line-height: 26px;
}

.top-wrapper {
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: zi(menu) + 20;
}

.mobile-nav {
  display: none;

  @include below(sm) {
    display: block;
  }
}

.dummy-nav {
  @include background_image("", "overflow_logo.svg");
  background-position: 30% center;
}

@import "../scss/variables";

.main-nav {
  height: 56px;
  align-items: center;
  border-bottom: 1px $brand_color solid;
  background-color: #fff;
  font-size: 14px;

  &__logo {
    background-image: url("../img/g_hub_white.svg");
    width: 85px;
    height: 41px;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;

    // &:hover {
    //     background-image: url('../img/ghub_logo_active.svg');
    // }
  }

  &__nav_items {
    margin-left: 33px;
  }

  .main-nav__item {
    &-link {
      margin: 0 5px;
      padding: 10px 20px;
      border-radius: 70px;

      &--active,
      &:hover,
      &:focus {
        background-color: $brand_color;
        color: #fff;
      }

      &--warn {
        &:hover,
        &focus {
          background-color: $error_red;
        }
        background-color: $warn_yellow;
        color: #fff;
      }
    }
  }
}
</style>
