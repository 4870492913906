// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/overflow_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../img/g_hub_white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".info-text {\n  font-weight: 600 !important;\n}\n@media (max-width: 767px) {\n.desktop-nav {\n    display: none;\n}\n}\n.delivery-header-text {\n  align-content: center;\n  font-size: 26px;\n  line-height: 26px;\n}\n.top-wrapper {\n  background-color: #fff;\n  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);\n  position: relative;\n  z-index: 4020;\n}\n.mobile-nav {\n  display: none;\n}\n@media (max-width: 767px) {\n.mobile-nav {\n    display: block;\n}\n}\n.dummy-nav {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: 30% center;\n}\n.main-nav {\n  height: 56px;\n  align-items: center;\n  border-bottom: 1px #93BD20 solid;\n  background-color: #fff;\n  font-size: 14px;\n}\n.main-nav__logo {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  width: 85px;\n  height: 41px;\n  background-position: left center;\n  background-repeat: no-repeat;\n  cursor: pointer;\n  display: block;\n}\n.main-nav__nav_items {\n  margin-left: 33px;\n}\n.main-nav .main-nav__item-link {\n  margin: 0 5px;\n  padding: 10px 20px;\n  border-radius: 70px;\n}\n.main-nav .main-nav__item-link--active, .main-nav .main-nav__item-link:hover, .main-nav .main-nav__item-link:focus {\n  background-color: #93BD20;\n  color: #fff;\n}\n.main-nav .main-nav__item-link--warn {\n  background-color: #ffcc00;\n  color: #fff;\n}\n.main-nav .main-nav__item-link--warn:hover, .main-nav .main-nav__item-link--warnfocus {\n  background-color: #ff1744;\n}", ""]);
// Exports
module.exports = exports;
